import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import {
	IInventory,
	IInventoryProduct,
	IPurchaseOrder,
	PurchaseOrderInventoryOutgoing,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { ESValidator } from "app/shared/es-validator";
import { Globals } from "app/shared/modules/globals/globals.service";
import { dateIsBefore } from "app/shared/time-format";
import { handleObservableError } from "app/shared/utils";
import { EMPTY } from "rxjs";
import { catchError, takeUntil, tap, timeout } from "rxjs/operators";
import _ from "lodash";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	onVendorChange,
	didItemChange,
} from "../shared";

import { getDestinationInventory } from "./utils";
import {
	IShippingOrderAllocateInventory,
	ShippingOrderAllocateInventoryBySkuSchema,
	ShippingOrderAllocateInventorySchema,
} from "./schemas";
import { ShippingOrderAllocateInventoryByIdsSchema } from "./schemas/so-allocate-inventory-by-ids";

@Component({
	selector: "so-allocate-inventory",
	templateUrl: "../form-view-for-dynamic.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ShippingOrderAllocateInventoryComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy, OnInit
{
	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), this.minDate);
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private purchase_order: IPurchaseOrder;
	private readonly bySku: boolean | null;
	private readonly byIds: boolean | null;
	private pendingInventory: IInventory | null;
	private allocatedInventory: IInventory | null;
	private existingInventory: IInventory | null;
	private copiedInventory: PurchaseOrderInventoryOutgoing | null;
	private minDate: Date;
	private dateValidatorFailed: boolean;
	private purchaseOrderVendorIsCustomer: boolean;
	private readonly whatChanged: SimpleChanges = {};
	private cacheModel = "";
	private userChangesEnabled = false;
	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store);
		this.form_title = "Allocate Inventory to Shipping Order";
		this.form_title_translation_key = marker(
			"form_title_allocate_inventory_to_shipping_order",
		);

		this.submit_button = "Allocate";
		this.submit_button_translation_key = marker("word_allocate");
		this.submit_icon = "dolly";

		this.bySku = this._injector.get("by_sku", null);
		this.byIds = this._injector.get("by_ids", null);
		if (this.bySku) {
			this.schema = ShippingOrderAllocateInventoryBySkuSchema();
		} else if (this.byIds) {
			this.schema = ShippingOrderAllocateInventoryByIdsSchema();
		} else {
			this.schema = ShippingOrderAllocateInventorySchema();
		}
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		// Purchase order we're allocating inventory to
		this.purchase_order = this._injector.get("purchase_order", null);
		this.minDate = new Date(
			this.purchase_order.po_date
				? this.purchase_order.po_date
				: this.purchase_order.created_at,
		);
		this.purchaseOrderVendorIsCustomer =
			this.purchase_order.vendor?.customer || false;
		// Line item in PO we're allocating inventory to (pending inventory)
		this.pendingInventory = this._injector.get("pending_inventory", null);
		this.allocatedInventory = this._injector.get("allocated_inventory", null);
		this.existingInventory = this.pendingInventory ?? this.allocatedInventory;
		// Only passed if using the copy line item feature
		this.copiedInventory = this._injector.get("copied_inventory", null);
		const destinationInventory = getDestinationInventory(
			this.existingInventory,
			this.copiedInventory?.allocated_inventory ?? null,
			"credit_event",
		);

		this.model = {
			purchase_order_id: this.purchase_order.id,
			inventory_product_id:
				this.existingInventory?.inventory_product_id ??
				destinationInventory?.inventory_product_id,
			inventory_unit_id:
				this.existingInventory?.inventory_unit_id ??
				destinationInventory?.inventory_unit_id,
			purchase_order_vendor_is_customer: this.purchaseOrderVendorIsCustomer,
			// Copied data, default to pending info if it exists
			...(!this.byIds && {
				lot_id: destinationInventory?.lot_id,
				batch_id: destinationInventory?.batch_id,
				sku_id: destinationInventory?.sku?.id,
				status_id: destinationInventory?.status_id,
			}),
		};

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		// If no pending inventory then we're allocating inventory directly and will have to select a product unless we're selecting by id
		if (!this.existingInventory && !this.byIds) {
			this.schema.properties.inventory_product_id.hidden = false;
		}

		// If allocating by ID lets assume we're using remaining by default
		if (this.byIds) {
			this.model.use_remaining = true;
		}

		this.schema.properties.purchase_order_id = {
			...this.schema.properties.purchase_order_id,
			default: this.purchase_order.id,
			readOnly: true,
		};

		// If we're allocating inventory to a line item then we need to set make the unit read only
		// this is the only accepted unit for the allocation endpoint
		if (this.pendingInventory) {
			this.schema.properties.inventory_unit_id = {
				...this.schema.properties.inventory_unit_id,
				default: this.pendingInventory.inventory_unit_id,
				value: this.pendingInventory.inventory_unit_id,
				readOnly: true,
			};
		}

		this._cd.detectChanges();

		// Enable user changes after we set everything up otherwise i'll mess up our initial model settings
		this.userChangesEnabled = true;
		this.onModelChange(this.model);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	/**
	 * uses JSON.stringify to compare the current model to the previous model.
	 * If the model has changed, then it will be considered for dynamic form changes (in getDynamicFormChanges)
	 * @param model
	 */
	onModelChange(model: IShippingOrderAllocateInventory) {
		const modelString = JSON.stringify(model);
		if (this.cacheModel !== modelString) {
			if (this.userChangesEnabled) {
				getDynamicFormChanges(this.whatChanged, model, this.bySku ?? false);
				this.updateDynamicForm(model);
			}
			// bc we use form-view-dynamic.html, we have to manually update some stuff that's not handled in onChanges
			this.model = {
				...this.model,
				choose_source_lot: model.choose_source_lot,
				choose_source_inventory: model.choose_source_inventory,
				use_remaining: model.use_remaining,
				value: model.value,
				inventory_unit_id: model.inventory_unit_id,
				timestamp: model.timestamp,
			};
			this.cacheModel = JSON.stringify({ ...model });
		}
	}

	onChanges(model: IShippingOrderAllocateInventory) {
		this.onModelChange(model);
	}

	createItem(inventory: IShippingOrderAllocateInventory) {
		const inventory_create = {
			...inventory,
			vendor_id: this.model.vendor_id ?? this.purchase_order.vendor_id,
			order_inventory_id: this.pendingInventory?.id,
			allocated_inventory_id: this.allocatedInventory?.id,
		};

		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${this.purchase_order.id}/allocate`,
				inventory_create,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem: IPurchaseOrder) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	/**
	 * Checks if a form element has changed since the last time
	 * If true, runs the function to update this.model
	 * If even one form element has changed, then 'Amount Remaining' will also be updated
	 * Tries to avoid running detectChanges unnecessarily; they're ran in changeFormByRequireBatch() and updateInventoryTotals() .
	 * @param model
	 */
	updateDynamicForm = (model?: any) => {
		let detectChangeNeeded = false;
		if (didItemChange(this.whatChanged.inventory_product_id)) {
			this.onInventoryProductChange(model);
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.batch_id)) {
			this.onBatchChange();
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.lot_id)) {
			this.onLotChange();
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.inventory_ids)) {
			this.onInventoryChange();
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.vendor_id)) {
			this.onVendorChange();
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.timestamp)) {
			this.onTimeStampChange();
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.sku_id)) {
			this.onSkuChange();
			detectChangeNeeded = true;
		}

		if (detectChangeNeeded) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					const amountAvailable = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");
					this.setThisModelValue("amount_available", amountAvailable);

					this._cd.detectChanges();
				},
			);
		}
	};

	onSkuChange = () => {
		this.setThisModelValue("sku_id", this.whatChanged.sku_id!.currentValue);
	};

	/**
	 * When a change in inventory_product_id is detected, this function is called.
	 * GETs the inventory_product, with special attention to 'require batch'.
	 * After the change, the currently selected batch/lot are no longer valid so we clear those.
	 */
	onInventoryProductChange = (model?: any) => {
		this.setThisModelValue(
			"inventory_product_id",
			this.whatChanged.inventory_product_id!.currentValue,
		);
		if (this.whatChanged.inventory_product_id!.currentValue) {
			const inventory_product_id =
				this.whatChanged.inventory_product_id!.currentValue;
			this._itemService
				.fetchItem(`inventory_product`, `${inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product: IInventoryProduct) => {
					this.model.inventory_unit_id = inventory_product.display_unit_id;
					this.changeFormByRequireBatch(inventory_product.require_batch, model);
					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);
				});
		} else {
			this.setThisModelValue("batch_id", undefined);
			this.setThisModelValue("lot_id", undefined);
		}
	};

	/**
	 * Changes Form rules dependent on inventory_product.require_batch.
	 * 		true: show batch_id and choose_source_lot. lot_id and inventory_ids' visibility will depend on batch_id.
	 * 				set this.model.batch_id to null, so that once detectChanges is ran it will automatically trigger onBatchChange()
	 * 		false: hide batch_id and choose_source_lot. lot_id and inventory_ids' visibility will depend on inventory_product_id.
	 * 				set this.model.lot_id to null, so that once detectChanges is ran it will automatically trigger onLotChange()
	 * @param require_batch inventory_product.require_batch
	 */
	changeFormByRequireBatch = (require_batch: boolean, model?: any) => {
		if (require_batch) {
			this.schema.properties.batch_id.hidden = false;
			if (this.schema.properties.choose_source_lot) {
				// Only use this if selecting by sku
				this.schema.properties.choose_source_lot.hidden = false;

				this.schema.properties.lot_id.visibleIf = {
					allOf: [
						{ choose_source_lot: [true], batch_id: ["$EXP$ target.value > 0"] },
					],
				};

				this.schema.properties.inventory_ids.visibleIf = {
					allOf: [
						{ choose_source_inventory: [true] },
						{ batch_id: ["$EXP$ target.value > 0"] },
					],
				};
			}

			this.setThisModelValue("batch_id", undefined);
		} else {
			this.schema.properties.batch_id.hidden = true;
			if (this.schema.properties.choose_source_lot) {
				// Only use this if selecting by sku
				this.schema.properties.choose_source_lot.hidden = true;

				this.schema.properties.lot_id.visibleIf = {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				};
				this.schema.properties.inventory_ids.visibleIf = {
					allOf: [
						{ choose_source_inventory: [true] },
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				};
			}

			// this.setThisModelValue("batch_id", null);
			this.model = {
				...this.model,
				...model,
				lot_id: undefined,
			};
		}

		this._cd.detectChanges();
	};

	/**
	 * When a change in batch_id is detected, this function is called.
	 * After the change, the currently selected lot_id is no longer valid so we clear it.
	 */
	onBatchChange = () => {
		this.setThisModelValue("batch_id", this.whatChanged.batch_id!.currentValue);
		this.setThisModelValue("lot_id", undefined);
	};

	/**
	 * When a change in lot_id is detected, this function is called.
	 * After the change, the currently selected inventory_ids are no longer valid so we clear them.
	 */
	onLotChange = () => {
		this.setThisModelValue("lot_id", this.whatChanged.lot_id!.currentValue);
		this.setThisModelValue("inventory_ids", []);
	};

	/**
	 * When a change in lot_id is detected, this function is called.
	 * we don't have to clear anything because inventory_ids is the last widget in the form.
	 */
	onInventoryChange = () => {
		// For some reason, using this.setThisModelValue for inventory_ids can cause the form to not update
		this.model.inventory_ids = this.whatChanged.inventory_ids!.currentValue;
	};

	/**
	 * When a change in timestamp is detected, this function is called.
	 */
	onTimeStampChange = () => {
		this.setThisModelValue("timestamp", this.whatChanged.timestamp!.currentValue);
	};

	/**
	 * On vendor change
	 */
	onVendorChange = () => {
		this.setThisModelValue("vendor_id", this.whatChanged.vendor_id!.currentValue);
		if (
			this.whatChanged.vendor_id!.currentValue !==
			this.whatChanged.vendor_id!.previousValue
		) {
			this.setThisModelValue("batch_id", undefined);
			this.setThisModelValue("lot_id", undefined);
		}
	};

	/**
	 * Function meant to set this.model's properties.
	 * this.model changes are important, but this.model.x = [...] is easy to miss so this function is here for more visibility.
	 * @param key
	 * @param value
	 */
	setThisModelValue = (key: string, value: any) => {
		this.model = {
			...this.model,
			[key]: value,
		};
	};
}
